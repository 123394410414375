import React from "react"
import { connect } from "react-redux"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"
import websitesSubpageStyles from "src/css/website_subpage.module.css"

import depSitePreview from "src/images/depSitePreview.png"
import websitesPage from "src/images/responsive-design.png"


const Websites = ({naviW, isMobile}) => (
  <Layout>
    <SEO title="Strony WWW - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={3} iconBg={divisions.website.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true} black={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "STRONY WWW",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Zmieniaj standardy", id: "zmieniaj-standardy" },
          { title: "Znajdź własny styl", id: "znajdz-wlasny-styl" },
          { title: "Cennik", id: "cennik" },
        ],
      }} style={{ backgroundColor: "white", color: "black" }} empty={true}>
        <div className={websitesSubpageStyles.screen1st} style={{width: isMobile.isMobile ? "100%" :"calc(100vw - " + naviW.naviW + "px)"}}>
          <div className={websitesSubpageStyles.screen1st_box}>
            <div className={websitesSubpageStyles.first_slogan}>POSTAW NA KLAROWNOŚĆ</div>
            <div className={websitesSubpageStyles.first_subtitle}>POKAŻ SWÓJ PRODUKT</div>
            <div className={websitesSubpageStyles.first_subtitle}>NIE PRZYTŁACZAJ INFORMACJAMI</div>
            <div className={websitesSubpageStyles.first_subtitle}>SPRZEDAWAJ PRODUKT, USŁUGĘ, WIZERUNEK</div>
            <div className={websitesSubpageStyles.first_subtitle}>ZAUFAJ NAM, ŻE WIEMY CO JEST MODNE I ORYGINALNE</div>
          </div>
        </div>
      </SubpageFirstScreen>



      <div className={[subpageStyles.subpage_block, subpageStyles.black, websitesSubpageStyles.screend2nd].join(" ")}
           id="zmieniaj-standardy">
        <img src={depSitePreview}/>
        <div className={websitesSubpageStyles.text}>
          <div className={websitesSubpageStyles.first_slogan}>Zmieniaj standardy</div>
          <div className={websitesSubpageStyles.first_subtitle}>Twórz innowacje</div>
        </div>
      </div>




      <div className={[subpageStyles.subpage_block, websitesSubpageStyles.screen3rd].join(" ")} id="znajdz-wlasny-styl">
        <div className={websitesSubpageStyles.screen3rd_box}>
          <div className={websitesSubpageStyles.subpage_slogan}>Znajdź własny styl</div>
          <div className={websitesSubpageStyles.subpage_first_screen_content_layout}>
            <div className={websitesSubpageStyles.subpage_image}>
              <img src={websitesPage}/>
            </div>
            <div className={websitesSubpageStyles.subpage_first_screen_space}/>
            <div className={websitesSubpageStyles.subpage_first_screen_text}>
              <div className={websitesSubpageStyles.text_block}>
                <div className={websitesSubpageStyles.subtitle}>Internetowa wizytówka</div>
                <div className={websitesSubpageStyles.text}>
                  Nie strać klienta przez brak atrakcyjnej strony WWW. Pozwól nam zająć się jej wykonaniem.
                </div>
              </div>
              <div className={websitesSubpageStyles.text_block}>
                <div className={websitesSubpageStyles.subtitle}>Lorem ipsum</div>
                <div className={websitesSubpageStyles.text}>Oferujemy projektowanie i wykonawstwo nowoczesnych witryn
                  internetowych, tworzymy aplikacje webowe i mobilne oraz systemy do zarządzania przedsiębiorstwem na
                  potrzeby naszego klienta.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PriceList id="cennik" content={{
        items: [
          { title: "Projekt graficzny storny WWW", desc: "", price: "200 zł", prefix: "od" },
          { title: "Prosta strona internetowa", desc: "Projekt gratis", price: "1600 zł", prefix: "od" },
          { title: "Sklep internetowy", desc: "Projekt gratis", price: "3500 zł", prefix: "od" },
          { title: "Audyt SEO", desc: "Poprawa pozycjonowania", price: "500 zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  naviW: state.naviW,
  isMobile: state.isMobile,
}), null)(Websites)


